import React from "react"
import { Link } from "gatsby"
import moment from "moment";
import api from "../../lib/api"
import Layout from "../../components/layout"
import Icon from "../../components/icon"
import PageTitle from "../../components/page-title"
import ProductImage from "../../components/product-image"
import { BlackLinkOut } from "../../components/black-buttons"
// import { Route, Redirect } from 'react-router-dom';
import { Router, navigate } from '@reach/router';
import _get from "lodash/get";
import 'babel-polyfill';

// <ProductImage product={ props.product } idx={ 1 } />

const StatusBlock = (props) => (
	<div className={'dib w-80 w-auto-ns mb3 mb0-ns ph3 ba br1 fl-ns mr4 anim-in-super-slow ' + (props.active ? ' b--black-90 black-80 ' : ' b--black-10 black-10 ') }>
		<Icon className='ma0 pa0 dib v-mid' icon={ props.icon } style={{ width: '1.5rem', height: '1.5rem' }} />
		<p className='f5 dib v-mid pl3'>{ props.title }</p>
	</div>
)

const OrderItem = (props) => (
	<li className={'cf mt3 ' + (props.has_border ? ' bb b--black-10' : '')}>
		<div className='w-80 dib v-mid'>
			<p className='f6 ma0 pa0 mb2 black-90'>{ props.item.Product }</p>
			<p className='f6 ma0 pa0 mb2 black-50'>{ props.item.Material }, { props.item.Color }</p>
			{ props.item.Customization &&
				<p className='f6 ma0 pa0 mb2 black-50'>Customization: { props.item.Customization }</p>
			}
			{ props.item.Range &&
				<p className='f6 ma0 pa0 mb2 black-50'>Numbers: { props.item.Range.split(",").join(" — ") }</p>
			}
			{ props.item.Size &&
				<p className='f6 ma0 pa0 mb2 black-50'>Size: { props.item.Size }</p>
			}
			{ props.item.Names &&
				<p className='f6 ma0 pa0 mb2 black-50'>Names: { props.item.Names }</p>
			}
			{ props.item["First Course"] &&
				<p className='f6 ma0 pa0 mb2 black-50'>First Course: { props.item["First Course"] }</p>
			}
			{ props.item["Second Course"] &&
				<p className='f6 ma0 pa0 mb2 black-50'>Second Course: { props.item["Second Course"] }</p>
			}
			{ props.item["Dessert"] &&
				<p className='f6 ma0 pa0 mb2 black-50'>Dessert: { props.item["Dessert"] }</p>
			}
		</div><div className='w-20 dib tr v-mid'>
			<span className='db f4 black-90 tr'>x{ props.item.Quantity }</span>
		</div>
	</li>
)


class Order extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			has_error: false,
			error: '',
			order: false,
			order_id: props.id,
			pending: true
		}
	}
	componentDidMount() {
		// debugger;
		this.fetchOrder(this.state.order_id);
	}
	fetchOrder = async (id) => {
		try {
			const body = { id: id.split("-").join("") }
			const response = await api.fetchOrder(body);
			const error = _get(response, 'error', false);
			if(error) {
				this.setState({ has_error: true, error: error, pending: false })
				return;
			}
			this.setState({ order: response.fields, pending: false });
		} catch(error) {
			console.log('caught this: ', error);
		}
	}
	renderShipping = () => {
		const data = this.state.order;
		if(data["Shipping Method"] === "Local Pick Up") {
			return (
				<div className='w-100 mb4 mb0-ns w-third-ns fl pr5-ns anim-in-super-slow'>
					<p className='ma0 pa0 pb2 black-50 f6 bb b--black-10'>Shipping Method</p>
					<p className='black-80 mt3 f4 lh-copy' style={{ whiteSpace: 'pre-wrap' }}>Local Pick Up</p>
				</div>
			)
		}
		return (
			<div className='w-100 mb4 mb0-ns w-third-ns fl pr5-ns anim-in-super-slow'>
				<div className='br1 bg-black-90 pa2 pv3 tc nt3 mb4 dn'>
					<p className='ttu tracked white-90 f6 ma0 mb3 pa0'>Tracking Number</p>
					<p className='white f4 ma0 pa0'>{ data['Tracking Number'] }</p>
				</div>
				<p className='ma0 pa0 pb2 black-50 f6 bb b--black-10'>Shipping Address</p>
				<p className='black-80 mt3 f4 lh-copy' style={{ whiteSpace: 'pre-wrap' }}>{ data['Shipping Address'] }</p>
			</div>
		)
	}
	renderError = () => {
		const error = this.state.error;
		if(error === "NOT_FOUND") {
			return (
				<div>
					<p className='f4 black-80 ma0 pa0'>Order not found.</p>
					<p className='black-80 mb4'>If this doesn't look right, click the big button below to send us an email and we'll look into it ASAP.</p>
					<BlackLinkOut to={"mailto:support@610designstudio.com?subject=Order%20Not%20Found:%20" + this.state.order_id} title="Email Us" />
				</div>
			)
		}
		return (
			<div>
				<p className='f4 black-80 ma0 pa0'>Error finding this order.</p>
				<p className='black-80 mb4'>This is a temporary problem, and it should be fixed soon. If you keep running into this error, though, don't hesitate to email us about it.</p>
				<BlackLinkOut to={"mailto:support@610designstudio.com?subject=Order%20Not%20Found:%20" + this.state.order_id} title="Email Us" />
			</div>
		)
	}
	renderStatus = (order) => {
		// const data = this.state.order;
		const status = order['Stripe Status'];
		const paid = status === "paid";
		const method = order["Shipping Method"];
		const delivered = status === 'delivered';
		const shipped = status === 'fulfilled';
		if(method === "Local Pick Up") {
			const ready = status.stripe === "fulfilled";
			return (
				<div>
					<div className='cf pt4'>
						<StatusBlock active={ paid } title="Confirmed" icon="credit-card" />
						<StatusBlock active={ ready } title="Ready For Pick Up" icon="home" />
					</div>
					<p className='f5 black-90 pt2 w-60-ns lh-copy'>Your order is in progress. We'll send an email to { order['Customer Email'] } as soon as it's ready for pick up.</p>
				</div>
			)
		}
		return (
			<div>
				<div className='cf pt4'>
					<StatusBlock active={ paid || shipped } title="Confirmed" icon="credit-card" />
					<StatusBlock active={ shipped } title="Shipped" icon="truck" />
					<StatusBlock active={ delivered } title="Delivered" icon="home" />
				</div>
				{ paid &&
					<p className='f5 black-90 pt2 w-60-ns lh-copy'>
						{`Your order is in progress. We'll send an email to ${order['Customer Email']} as soon as it ships.`}
					</p>
				}
				{ shipped &&
					<p className='f5 black-90 pt2 w-60-ns lh-copy'>
						Your order is on its way! The tracking number is <a target='_blank' className='blue no-underline dim' href={ order['Tracking URL'] }>{ order['Tracking Number'] }.</a>
					</p>
				}
			</div>
		)
	}
	renderOrder = () => {
		const data = this.state.order;
		return (
			<div className=''>
				<div className='mb4'>
					<p className='f3 black-90 ma0 pa0'>Welcome back, { data['Buyer Name'] }. Here's the latest update on your order.</p>
					{ this.renderStatus(data) }
				</div>
				<div className='cf pt4 pb3 pt4-ns'>
					<div className='w-100 mb4 mb0-ns w-third-ns fl pr5-ns anim-in-super-slow '>
						<p className='ma0 pa0 pb2 black-50 f6 bb b--black-10'>Order Summary</p>
						<ul className='list ma0 pa0 pt2'>
							{ data['Line Items'].map((item, idx) =>
								<OrderItem key={ idx } item={ item } />
							  )
							}
						</ul>
					</div>
					{ this.renderShipping() }
					<div className='w-100 w-third-ns fl pr5-ns anim-in-super-slow'>
						<p className='ma0 pa0 pb2 black-50 f6 bb b--black-10'>Billing Information</p>
						<p className='black-80 mt3 f4'>XXXX-XXXX-{ data['Last Four'] }</p>
						<p className='black-80 f5 black-50'>${ data['Amount'] } charged on { moment.unix(data['Stripe Created']).format('MMMM Do, YYYY') }.</p>
					</div>
				</div>
			</div>
		)
	}
	render() {
		const record = this.state.order;
		const o = function(key) {
			return record[key];
		}
		return (
			<Layout>
				<div className='ph3 ph4-ns pt3 pt0-ns'>
					<PageTitle title={ "Order #" + this.state.order_id } />
					<div className='cf pb5'>
						{ this.state.has_error && this.renderError() }
						{ this.state.pending &&
							<div>
								<Icon className='ma0 pa0 dib black-50 anim-rotate' icon={ 'loader' } style={{ width: '2rem', height: '2rem' }} />
								<p className='f5 black-90 i'>Finding your order. Sit tight.</p>
							</div>
						}
						{ !this.state.pending && this.state.order && this.renderOrder() }
					</div>
				</div>
			</Layout>
		)
	}
}

//<Route
  //location={location}
  //key={location.key}
  //path="/order/:orderId/"
  //render={props => <Order {...props} />}
///>

const OrderPage = ({ ...rest }) => {
	console.log('fucking kill me');
  const split = rest.location.pathname.split('/');
  console.log('split', split);
  if(split.length >= 3) {
  	const id = split[2];
  	return (

  		<Router>
  			<Order { ...rest } id={ id } />
  		</Router>

  	);
  }
  // navigate('/');
  console.log('didnt fucking work');
  return null;
};

export default OrderPage;